<template>
  <div v-if="false">
    <div class="caption">Количество отзывов за период</div>
    <div class="months" v-for="item in reviewsData" :key="item.name">
      <AnalyticsPeriodWidget :data="item" class="month" />
    </div>
    <a href>Смотреть аналитику</a>
  </div>
</template>

<script>
import AnalyticsPeriodWidget from "@/components/common/AnalyticsPeriodWidget";

export default {
  name: "AnalyticsWidget",
  data() {
    return {
      reviewsData: [
        {
          name: "Январь",
          value: "26",
          delta: 16.2,
        },
        {
          name: "Февраль",
          value: "+36",
          delta: 16.2,
        },
      ],
    };
  },
  components: {
    AnalyticsPeriodWidget,
  },
};
</script>
