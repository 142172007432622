import { isLogged } from "@/helpers/authHelper";

import Home from "../views/common/Home";
import Admin from "../views/common/Admin";
import PageNotFound from "../views/common/PageNotFound";
import Registration from "../views/common/Registration";
import PasswordRecoveryView from "../views/common/PasswordRecoveryView";
import Version from "../views/common/Version";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: (to, from, next) => {
      if (isLogged()) next("/admin");
      next();
    },
  },
  {
    path: "/registration",
    name: "Registration",
    component: Registration,
  },
  {
    path: "/password_recovery",
    name: "Password recovery",
    component: PasswordRecoveryView,
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    beforeEnter: (to, from, next) => {
      if (!isLogged()) next("/");
      next();
    },
    children: [
      {
        path: "",
        redirect: "/admin/reviews",
      },
      {
        path: "reviews",
        name: "Отзывы",
        component: () => import("@/components/common/Reviews"),
      },
      {
        path: "analytics",
        name: "Аналитика",
        component: () => import("@/components/common/Analytics"),
        meta: { fullname: "Ежедневная статистика отзывов" },
      },
      {
        path: "employees",
        name: "Сотрудники",
        component: () => import("@/components/common/Employees"),
      },
      {
        path: "list-of-objects",
        name: "Список локаций",
        component: () => import("@/components/common/ListOfObjects"),
      },
      {
        path: "payment-hystory",
        name: "История платежей",
        component: () => import("@/components/common/PaymentHystory"),
      },
      {
        path: "payment-for-services",
        name: "Оплата услуг",
        component: () => import("@/components/common/PaymentForServices"),
      },
    ],
  },

  {
    path: "/v",
    name: "Version",
    component: Version,
  },

  {
    path: "*",
    component: PageNotFound,
  },
];

const routesLaps = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/4laps/Home.vue"),
    beforeEnter: (to, from, next) => {
      if (isLogged()) next("/admin");
      next();
    },
  },
  // {
  //   path: "/registration",
  //   name: "Registration",
  //   component: () => import("@/views/4laps/Registration.vue"),
  // },
  {
    path: "/password_recovery",
    name: "Password recovery",
    component: () => import("@/views/4laps/PasswordRecoveryView.vue"),
  },
  {
    path: "/admin",
    name: "Admin",
    component: () => import("@/views/4laps/Admin.vue"),
    beforeEnter: (to, from, next) => {
      if (!isLogged()) next("/");
      next();
    },
    children: [
      {
        path: "",
        redirect: "/admin/list-of-objects",
      },
      {
        path: "reviews",
        name: "Отзывы",
        component: () => import("@/components/4laps/Reviews"),
      },
      {
        path: "analytics",
        name: "Аналитика",
        component: () => import("@/components/4laps/Analytics"),
        meta: { fullname: "Ежедневная статистика отзывов" },
      },
      // {
      //   path: "employees",
      //   name: "Сотрудники",
      //   component: () => import("@/components/4laps/Employees"),
      // },
      {
        path: "list-of-objects",
        name: "QR-codes",
        component: () => import("@/components/4laps/ListOfObjects"),
      },
      {
        path: "payment-hystory",
        name: "История платежей",
        component: () => import("@/components/4laps/PaymentHystory"),
      },
      {
        path: "payment-for-services",
        name: "Оплата услуг",
        component: () => import("@/components/4laps/PaymentForServices"),
      },
    ],
  },

  {
    path: "/v",
    name: "Version",
    component: Version,
  },

  {
    path: "*",
    component: PageNotFound,
  },
];

export { routes, routesLaps };
