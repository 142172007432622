<template>
  <aside>
    <UserWidget class="user" />
    <LeftMenu class="left_menu" />
    <AnalyticsWidget class="stat" />
  </aside>
</template>

<script>
import UserWidget from "@/components/common/UserWidget";
import AnalyticsWidget from "@/components/common/AnalyticsWidget";
import LeftMenu from "@/components/common/LeftMenu";

export default {
  name: "SideBar",
  components: {
    UserWidget,
    AnalyticsWidget,
    LeftMenu,
  },
};
</script>
