<template>
  <div>
    <div class="name">{{ data.name }}</div>
    <div class="data">
      {{ data.value }} -<span>{{ data.delta }}%</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "AnalyticsPeriodWidget",
  props: ["data"],
};
</script>

<style scoped>
.analytics-period-widget {
  border: 1px solid;
}
</style>
