<template>
  <div class="auth-center">
    <div>
      <Header />
      <ResponsiveAuth>
        <PasswordRecovery />
      </ResponsiveAuth>
    </div>
  </div>
</template>

<script>
import ResponsiveAuth from "@/components/common/ResponsiveAuth";
import PasswordRecovery from "@/components/common/PasswordRecovery";
import Header from "@/components/common/Header";

export default {
  name: "PasswordRecoveryView",
  components: {
    ResponsiveAuth,
    PasswordRecovery,
    Header,
  },
};
</script>

<style scoped></style>
