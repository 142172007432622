<template>
  <div class="enter-wrapper">
    <div class="enter-header">Успешно</div>
    <div class="enter-sub-header">Ваш новый пароль изменен</div>
    <div class="enter-circle">
      <svg
        width="186"
        height="186"
        viewBox="0 0 186 186"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle opacity="0.13" cx="93" cy="93" r="93" fill="#72B854" />
        <path
          d="M75.8393 112.19C74.6699 113.349 72.7847 113.349 71.6153 112.19L50.5438 91.3025C49.3744 90.1433 47.4892 90.1433 46.3198 91.3025L42.1494 95.4366C40.9654 96.6102 40.9654 98.5241 42.1494 99.6978L71.6153 128.906C72.7847 130.066 74.6699 130.066 75.8393 128.906L143.851 61.4888C145.035 60.3151 145.035 58.4013 143.851 57.2276L139.68 53.0936C138.511 51.9344 136.626 51.9344 135.456 53.0936L75.8393 112.19Z"
          fill="#72B854"
        />
      </svg>
    </div>
    <form class="enter-form" @submit.prevent="enter">
      <div class="enter-submit-button-wrapper">
        <button class="enter-submit-button" type="submit">Войти</button>
      </div>
    </form>
  </div>
</template>

<script>
// import axios from "axios";

import { login } from "@/helpers/authHelper";

export default {
  name: "PasswordRecovery",
  data() {
    return {
      apiUrl: process.env.VUE_APP_SERVER_URL + process.env.VUE_APP_API_PATH,
    };
  },
  methods: {
    enter: function () {
      login({ access_token: 1 });
      this.$router.push("/admin");
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap");

.enter-header {
  font-weight: 900;
  font-size: 34px;
  line-height: 32px;
  color: black;
  width: 100%;
  text-align: left;
}

.enter-sub-header {
  padding-top: 15px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: black;
  width: 100%;
  text-align: left;
  content: "";
}

.enter-circle {
  padding: 45px 0px;
}

.enter-submit-button-wrapper {
  padding-top: 0px;
}

.enter-submit-button {
  width: 100%;
  background-color: var(--use-color-primary-600);
  color: white;
  border: none;
  padding: 16px;
  font-weight: 500;
  font-size: 18px;
  border-radius: 2px;
  height: 55px;
  line-height: 24px;
  cursor: pointer;
}

@media (max-width: 507px) {
  .enter-header {
    font-size: 18px;
    padding-top: 28px;
  }
}
</style>
