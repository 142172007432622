<template>
  <a-drawer
    placement="right"
    :closable="false"
    :visible="showDrawer"
    @close="close"
    :width="computedWidth"
    :destroyOnClose="true"
  >
    <slot></slot>
    <div v-if="showDrawer" class="closer" @click="close">
      <a-icon type="close" />
    </div>
  </a-drawer>
</template>

<script>
export default {
  name: "Drawer",
  props: ["showDrawer", "close", "maxWidth"],
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    windowWidth(newHeight, oldHeight) {
      this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  computed: {
    computedWidth: function () {
      let drawerWidth = Math.min(this.windowWidth * 0.8, this.maxWidth);
      drawerWidth = Math.max(450, drawerWidth);
      return drawerWidth;
    },
  },
};
</script>

<style>
.ant-drawer-content-wrapper {
  padding: 0px 0;
}

.ant-drawer-content {
  border-radius: 15px 0px 0px 15px;
  overflow: visible !important;
}

.ant-drawer-body {
  padding: 0px !important;
}

.closer {
  position: absolute;
  top: 40px;
  left: -53px;
  width: 57px;
  height: 40px;
  padding-top: 10px;
  border-radius: 15px 0 0 15px;
  background: url("../../assets/img/close_bg.svg") 100% no-repeat;
  cursor: pointer;
}
</style>
