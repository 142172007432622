<template>
  <div class="auth-center">
    <div>
      <Header />
      <ResponsiveAuth>
        <Registration />
      </ResponsiveAuth>
    </div>
  </div>
</template>

<script>
import ResponsiveAuth from "@/components/common/ResponsiveAuth";
import Registration from "@/components/common/Registration";
import Header from "@/components/common/Header";

export default {
  name: "Home",
  components: {
    ResponsiveAuth,
    Registration,
    Header,
  },
};
</script>

<style scoped></style>
