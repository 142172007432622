<template>
  <div id="disclaimer">
    Нажимая кнопку “{{ text }}”,<br />
    вы соглашаетесь
    <a
      href="https://docs.google.com/document/d/18ccRmhu1-tFramWheRfVQ4-iEGMckv7pKJm1pt-xSW0"
      target="_blank"
      >с условиями использования</a
    >
  </div>
</template>

<script>
export default {
  name: "Disclaimer",
  props: ["text"],
};
</script>

<style scoped lang="scss">
#disclaimer {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #606060;

  a {
    text-decoration: underline;
  }
}
</style>
