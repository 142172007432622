import {axioWrapper} from "@/helpers/dataHelpers";

export function getPets(cb, query) {
  const req = {
    method: "get",
    url: `/pets${query ?? ''}`,
  };
  axioWrapper(req, cb);
}

export function postPets(data, cb) {
  const req = {
    method: "post",
    url: "/pets",
    data,
  };
  axioWrapper(req, cb);
}

export function putPets(id, data, cb) {
  const req = {
    method: "put",
    url: "/pets/" + id,
    data,
  };
  axioWrapper(req, cb);
}

export function deletePets(id, cb) {
  const req = {
    method: "delete",
    url: "/pets/" + id,
  };
  axioWrapper(req, cb);
}
