<template>
  <div>
    <div class="header">
      <div class="container">
        <div class="logo">
          <router-link to="/">
            <svg
              width="136"
              height="35"
              viewBox="0 0 97 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.06137 24.8651H11.4777C16.4831 24.8651 20.5391 20.809 20.5391 15.8037V13.3874C20.5391 8.38203 16.4831 4.32599 11.4777 4.32599H8.02578C7.1628 4.32599 6.29981 3.89449 5.78202 3.11781C4.74643 1.39183 3.02046 0.269947 0.949286 0.011051C0.431494 -0.0752477 0 0.356246 0 0.874039V12.0929V15.7174C0 20.809 4.05604 24.8651 9.06137 24.8651Z"
                fill="#006BFF"
              />
              <path
                d="M10.0106 20.8092H11.3914C14.1529 20.8092 16.3967 18.5655 16.3967 15.8039V14.4231C16.3967 11.6616 14.1529 9.41779 11.3914 9.41779H9.49278C8.97499 9.41779 8.54349 9.1589 8.2846 8.72741C7.68051 7.86442 6.73122 7.17403 5.60934 7.08773C5.17784 7.00143 4.91895 7.26033 4.91895 7.51922V13.7327V15.7176C4.91895 18.5655 7.24901 20.8092 10.0106 20.8092Z"
                fill="white"
              />
              <path
                d="M38.6621 7.51888V18.2199C38.6621 18.9966 38.4895 19.3418 37.6265 19.687C36.5909 20.1185 35.0375 20.6363 32.5348 20.6363C28.824 20.6363 27.098 18.6514 27.098 15.3721V7.51888C27.098 6.91478 27.2706 6.74219 27.8747 6.74219H29.3418C29.9459 6.74219 30.1185 6.91478 30.1185 7.51888V15.2858C30.1185 17.0117 31.0678 17.8747 32.9663 17.8747C34.0019 17.8747 34.6923 17.7884 35.3827 17.5295C35.6416 17.4432 35.6416 17.2706 35.6416 17.098V7.51888C35.6416 6.91478 35.8142 6.74219 36.4183 6.74219H37.7991C38.4895 6.74219 38.6621 6.91478 38.6621 7.51888Z"
                fill="#1D1D1B"
              />
              <path
                d="M46.5152 6.31055C49.5357 6.31055 51.2617 7.43243 52.1246 9.07211C52.4698 9.6762 52.2972 10.0214 51.6932 10.2803L50.485 10.7118C49.8809 10.9707 49.7083 10.8844 49.3631 10.3666C48.9316 9.5899 48.2412 8.98581 46.5152 8.98581C44.7893 8.98581 44.0989 9.4173 44.0989 10.3666C44.0989 11.5748 45.1344 11.8337 47.1193 12.0063C50.2261 12.3515 52.4698 13.0418 52.4698 16.2349C52.4698 18.8239 50.5713 20.5498 46.8604 20.5498C43.7537 20.5498 41.8551 19.428 41.0784 17.3568C40.9058 16.839 40.9921 16.4938 41.5962 16.2349L42.8044 15.8897C43.3222 15.7171 43.5811 15.8034 43.84 16.4075C44.2715 17.4431 45.2207 17.8746 46.7741 17.8746C48.5864 17.8746 49.5357 17.3568 49.5357 16.3212C49.5357 15.113 48.5001 14.8541 46.4289 14.6815C43.84 14.4226 41.1647 13.9048 41.1647 10.4529C41.0784 8.03652 42.8907 6.31055 46.5152 6.31055Z"
                fill="#1D1D1B"
              />
              <path
                d="M60.3231 6.31055C64.4654 6.31055 66.364 8.72691 66.364 11.6611V13.2144C66.364 13.9048 66.1051 14.3363 65.1558 14.3363H57.3026V15.2856C57.3026 17.0116 58.3382 17.8746 60.2368 17.8746C61.7901 17.8746 62.7394 17.3568 63.3435 16.4938C63.775 15.8897 64.0339 15.8034 64.638 16.1486L65.5873 16.6664C66.1914 16.9253 66.2777 17.3568 65.9325 17.9609C64.9832 19.5143 63.2572 20.4635 60.3231 20.4635C56.0944 20.4635 54.3684 18.1335 54.3684 14.8541V11.7474C54.2821 8.29542 56.3533 6.31055 60.3231 6.31055ZM60.3231 8.89951C58.3382 8.89951 57.3026 9.84879 57.3026 11.5748V12.0926H63.3435V11.4885C63.3435 9.8488 62.3079 8.89951 60.3231 8.89951Z"
                fill="#1D1D1B"
              />
              <path
                d="M80.258 20.2049H78.9636C78.0143 20.2049 77.8417 20.1186 77.5828 19.5146L75.9431 16.0626C75.5116 15.1133 74.9938 14.7681 73.8719 14.7681H72.3186V19.4283C72.3186 20.0323 72.146 20.2912 71.4556 20.2912H70.1611C69.4707 20.2912 69.2981 20.1186 69.2981 19.4283V1.73701C69.2981 1.13292 69.4707 0.874023 70.1611 0.874023H71.4556C72.146 0.874023 72.3186 1.04662 72.3186 1.73701V11.9203H73.5267H73.6993L77.2376 7.34643C77.6691 6.74234 77.928 6.74234 78.791 6.74234H80.0854C80.8621 6.74234 81.0347 7.08753 80.6032 7.69162L76.6335 12.5244C77.4102 12.9558 78.0143 13.6462 78.5321 14.6818L80.7758 19.2557C81.121 19.946 80.9484 20.2049 80.258 20.2049Z"
                fill="#1D1D1B"
              />
              <path
                d="M86.0401 3.20426V3.46315C86.0401 4.67133 85.6086 5.01653 84.4867 5.01653H84.3141C83.1059 5.01653 82.7607 4.58504 82.7607 3.46315V3.20426C82.7607 1.99607 83.1922 1.65088 84.3141 1.65088H84.4867C85.6086 1.65088 86.0401 1.99607 86.0401 3.20426ZM85.8675 7.51919V19.4284C85.8675 20.0325 85.6949 20.2051 85.0908 20.2051H83.6237C83.0196 20.2051 82.847 20.0325 82.847 19.4284V7.51919C82.847 6.9151 83.0196 6.74251 83.6237 6.74251H85.0908C85.6949 6.74251 85.8675 6.9151 85.8675 7.51919Z"
                fill="#1D1D1B"
              />
              <path
                d="M93.2028 4.41243V6.7425H96.137C96.7411 6.7425 96.9137 6.91509 96.9137 7.51919V8.72737C96.9137 9.33146 96.7411 9.50406 96.137 9.50406H93.2028V14.7683C93.2028 17.3572 93.9795 17.7887 96.137 17.7887C96.7411 17.7887 96.9999 17.9613 96.9999 18.5654V19.7736C96.9999 20.3777 96.8274 20.5503 96.137 20.6366C91.822 20.8092 90.1823 19.4284 90.1823 14.9409V9.50406H88.3701C87.766 9.50406 87.5934 9.33146 87.5934 8.72737V7.51919C87.5934 6.91509 87.766 6.7425 88.3701 6.7425H90.1823V4.41243C90.1823 3.80834 90.3549 3.63574 90.959 3.63574H92.3398C93.0302 3.63574 93.2028 3.80834 93.2028 4.41243Z"
                fill="#1D1D1B"
              />
            </svg>
          </router-link>
        </div>
        <div v-if="isLogged()" class="hamburger" @click="toggleMenu">
          <svg
            width="25"
            height="15"
            viewBox="0 0 25 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.6806 6.25006H1.31944C0.590735 6.25006 0 6.78172 0 7.43756V7.56256C0 8.2184 0.590735 8.75006 1.31944 8.75006H23.6806C24.4093 8.75006 25 8.2184 25 7.56256V7.43756C25 6.78172 24.4093 6.25006 23.6806 6.25006Z"
              fill="black"
            ></path>
            <path
              d="M23.6806 12.5001H1.31944C0.590735 12.5001 0 13.0317 0 13.6876V13.8126C0 14.4684 0.590735 15.0001 1.31944 15.0001H23.6806C24.4093 15.0001 25 14.4684 25 13.8126V13.6876C25 13.0317 24.4093 12.5001 23.6806 12.5001Z"
              fill="black"
            ></path>
            <path
              d="M23.6806 6.10352e-05H1.31944C0.590735 6.10352e-05 0 0.531723 0 1.18756V1.31256C0 1.9684 0.590735 2.50006 1.31944 2.50006H23.6806C24.4093 2.50006 25 1.9684 25 1.31256V1.18756C25 0.531723 24.4093 6.10352e-05 23.6806 6.10352e-05Z"
              fill="black"
            ></path>
          </svg>
        </div>
        <span v-if="$route.name === 'Home'">
          Станьте нашим клиентом &mdash;
          <router-link to="/registration">Зарегистрируйтесь</router-link>
        </span>
        <span v-if="$route.name === 'Registration'">
          Уже есть аккаунт &mdash; <router-link to="/">войти</router-link>
        </span>
        <span v-if="$route.name === 'Password recovery'">
          <router-link to="/">Войти</router-link>
        </span>

        <Logout />
      </div>
    </div>

    <div v-if="isLogged()" class="mobile_menu" :class="{ show: menuIsVisible }">
      <a class="close_menu" href="" @click.prevent="toggleMenu">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.87818 7.99886L15.606 2.28357C15.8568 2.03271 15.9977 1.69246 15.9977 1.33769C15.9977 0.98291 15.8568 0.642664 15.606 0.391799C15.3552 0.140934 15.015 0 14.6602 0C14.3055 0 13.9653 0.140934 13.7145 0.391799L8 6.12041L2.28552 0.391799C2.03469 0.140934 1.6945 -2.64329e-09 1.33977 0C0.985044 2.64329e-09 0.644846 0.140934 0.394017 0.391799C0.143188 0.642664 0.00227327 0.98291 0.00227327 1.33769C0.00227327 1.69246 0.143188 2.03271 0.394017 2.28357L6.12182 7.99886L0.394017 13.7142C0.269166 13.838 0.17007 13.9853 0.102444 14.1477C0.0348177 14.31 0 14.4842 0 14.66C0 14.8359 0.0348177 15.01 0.102444 15.1724C0.17007 15.3347 0.269166 15.4821 0.394017 15.6059C0.517848 15.7308 0.665174 15.8299 0.827496 15.8975C0.989818 15.9652 1.16392 16 1.33977 16C1.51562 16 1.68972 15.9652 1.85204 15.8975C2.01437 15.8299 2.16169 15.7308 2.28552 15.6059L8 9.87731L13.7145 15.6059C13.8383 15.7308 13.9856 15.8299 14.148 15.8975C14.3103 15.9652 14.4844 16 14.6602 16C14.8361 16 15.0102 15.9652 15.1725 15.8975C15.3348 15.8299 15.4822 15.7308 15.606 15.6059C15.7308 15.4821 15.8299 15.3347 15.8976 15.1724C15.9652 15.01 16 14.8359 16 14.66C16 14.4842 15.9652 14.31 15.8976 14.1477C15.8299 13.9853 15.7308 13.838 15.606 13.7142L9.87818 7.99886Z"
            fill="black"
          ></path></svg
      ></a>

      <div class="left">
        <div class="user">
          <UserWidget />
        </div>
        <div class="left_menu">
            <LeftMenu />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useKitImg from "../../assets/img/logo.svg";
import Logout from "@/components/common/Logout";
import LeftMenu from "@/components/common/LeftMenu";
import UserWidget from "@/components/common/UserWidget";
import { isLogged } from "@/helpers/authHelper";

export default {
  name: "Header",
  data() {
    return {
      useKitImg,
      menuIsVisible: false,
      isLogged,
    };
  },
  components: {
    Logout,
    LeftMenu,
    UserWidget,
  },
  methods: {
    toggleMenu() {
      this.menuIsVisible = !this.menuIsVisible;
    },
  },
};
</script>

<style scoped>
#header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.menu-fixed {
  position: fixed;
  right: 0;
  top: 0;
}
</style>

<style>
@media (max-width: 1000px) {
  #user-widget > div.top > div.edit > button {
    margin-right: 60px;
  }
}

@media (min-width: 1001px) {
  .mobile_menu {
    display: none !important;
  }
}
</style>
