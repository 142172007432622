<template>
  <div id="user-widget">
    <div class="top">
      <div class="today">
        {{ moment.utc().local().format("DD MMMM HH:mm") }}
      </div>
      <div v-if="!isManager()" class="edit">
        <a-button type="link" @click="() => (showDrawer = true)">
          <IconBase width="14" height="14" icon-name="редактировать"
            ><IconEditPencil
          /></IconBase>
        </a-button>
      </div>
    </div>
    <div class="middle">
      <div class="info">
        <div class="hello">
          {{ greating() }}, <span>{{ data.userName }}</span>
        </div>
        <div class="for_today">
          за сегодня
          <span :class="{ 'red-text': data.newRevies === 0 }"
            >{{ data.newRevies ? "+ " : "" }}{{ data.newRevies }} новых
            отзывов</span
          >
        </div>
      </div>
    </div>
    <Drawer
      :showDrawer="showDrawer"
      :close="() => (showDrawer = false)"
      :maxWidth="1100"
    >
      <EditProfile :close="() => (showDrawer = false)" />
    </Drawer>
  </div>
</template>

<script>
import moment from "moment";
import "moment/locale/ru";

import { bus } from "@/bus";

import userImg from "../../assets/img/user.png";
import IconBase from "./IconBase";
import IconEditPencil from "./IconBase/IconEditPencil";

import Drawer from "@/components/common/Drawer";
import EditProfile from "@/components/common/EditProfile";

import { getUserInfo } from "@/helpers/dataHelpers";
import { isManager } from "@/helpers/authHelper";

function mappingB2F(ob) {
  const retOb = {
    newRevies: ob.count,
    userName: ob.name,
  };

  retOb.userName += ob.surname ? " " + ob.surname : "";

  return retOb;
}

export default {
  name: "UserWidget",
  data() {
    return {
      data: {
        userName: "",
        newRevies: 0,
      },
      moment,
      userImg,
      isManager,
      showDrawer: false,
    };
  },
  methods: {
    greating() {
      let hour = moment().utc().local().hour();
      if (hour < 5) return "Доброй ночи";
      if (hour < 17) return "Добрый день";
      return "Добрый вечер";
    },
    getInfo() {
      getUserInfo((response) => {
        this.data = mappingB2F(response.data.data);
      });
    },
  },
  components: {
    IconBase,
    IconEditPencil,
    Drawer,
    EditProfile,
  },
  created() {
    bus.$on("CloseDrawer Profile", () => {
      this.showDrawer = false;
    });

    bus.$on("EditProfile update and close", () => {
      this.getInfo();
      this.showDrawer = false;
    });

    this.getInfo();
  },
  mounted() {},
};
</script>

<style scoped>
.widget-header,
.widget-body {
  display: flex;
  justify-content: space-between;
}

.red-text {
  color: red !important;
}
</style>
