<template>
<!--  <div class="layout">-->
<!--    <div class="layout__icon_container">-->
<!--      <img src="@/assets/FourLapsLogo.svg" />-->
<!--    </div>-->
    <router-view />
<!--  </div>-->
</template>

<script>
// import FourLapsLogoSVG from '@/assets/FourLapsLogo.svg?inline';

export default {
  name: "FourlapsLayout",
  components: {},
};
</script>

<style scoped lang="scss">
//.layout {
//  height: 100vh;
//  width: 100%;
//  background-image: url("~@/assets/4lapsBackground.png");
//  background-repeat: repeat;
//  background-size: 375px 656px;
//
//  &__icon_container {
//    margin-top: 28px;
//    margin-bottom: 70px;
//  }
//}
</style>
