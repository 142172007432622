<template>
  <div class="registration-wrapper">
    <div class="registration-header">Регистрация</div>
    <div class="registration-sub-header">

    </div>
    <a-form-model
      class="registration-form"
      :model="registrationForm"
      ref="registrationForm"
      :rules="rules"
      @submit="registration"
      @submit.native.prevent
    >
      <a-form-model-item has-feedback prop="name">
        <a-input
          placeholder="Имя"
          v-model="registrationForm.name"
          size="large"
          class="registration-input-wrapper"
        >
          <a-icon
            slot="prefix"
            type="user"
            :style="{ fontSize: '16px', color: '#08c' }"
          />
        </a-input>
      </a-form-model-item>

      <a-form-model-item has-feedback prop="email">
        <a-input
          placeholder="E-mail"
          v-model="registrationForm.email"
          size="large"
          class="registration-input-wrapper"
        >
          <a-icon
            slot="prefix"
            type="mail"
            :style="{ fontSize: '16px', color: '#08c' }"
          />
        </a-input>
      </a-form-model-item>

      <a-form-model-item has-feedback prop="phone">
        <a-input
          placeholder="Тел"
          v-model="registrationForm.phone"
          size="large"
          class="registration-input-wrapper"
        >
          <a-icon
            slot="prefix"
            type="phone"
            :style="{ fontSize: '16px', color: '#08c' }"
          />
        </a-input>
      </a-form-model-item>

      <a-form-model-item has-feedback prop="password">
        <a-input-password
          placeholder="Пароль"
          v-model="registrationForm.password"
          size="large"
          class="registration-input-wrapper"
        >
          <a-icon
            slot="prefix"
            type="lock"
            :style="{ fontSize: '16px', color: '#08c' }"
          />
        </a-input-password>
      </a-form-model-item>

      <a-form-model-item has-feedback prop="c_password">
        <a-input-password
          placeholder="Повторите пароль"
          v-model="registrationForm.c_password"
          size="large"
          class="registration-input-wrapper"
        >
          <a-icon
            slot="prefix"
            type="lock"
            :style="{ fontSize: '16px', color: '#08c' }"
          />
        </a-input-password>
      </a-form-model-item>

      <a-form-model-item prop="agreeCheckbox">
        <div class="registration-form-last-line">
          <div class="registration-agreeCheckbox-wrapper">
            <a-checkbox
              v-model="registrationForm.agreeCheckbox"
              class="registration-agreeCheckbox"
            ></a-checkbox>
            <div class="registration-agreeCheckbox-text">
              Я согласен с условиями обработки пероснальных данных
            </div>
          </div>
          <div class="i-have-account-wrapper">
            <router-link class="i-have-account" to="/">
              У меня есть аккаунт
            </router-link>
          </div>
        </div>
      </a-form-model-item>

      <div class="registration-submit-button-wrapper">
        <button class="registration-submit-button" type="submit">
          Регистрация
        </button>
      </div>
    </a-form-model>
  </div>
</template>

<script>
import * as EmailValidator from "email-validator";
import { isValidPhoneNumber } from "libphonenumber-js";

import { login } from "@/helpers/authHelper";
import { register } from "@/helpers/dataHelpers";

export default {
  name: "Registration",
  data() {
    let emailCheckPending;
    const validateEmail = (rule, value, callback) => {
      rule;
      clearTimeout(emailCheckPending);
      if (!value) {
        return callback(new Error("Пожалуйста, введите email"));
      }
      emailCheckPending = setTimeout(() => {
        if (EmailValidator.validate(value)) {
          callback();
        } else {
          callback(new Error("Email некорректен"));
        }
      }, 1000);
    };

    let phoneCheckPending;
    const validatePhone = (rule, value, callback) => {
      rule;
      clearTimeout(phoneCheckPending);
      if (!value) {
        return callback(new Error("Пожалуйста, введите номер телефона"));
      }
      phoneCheckPending = setTimeout(() => {
        if (isValidPhoneNumber(value, "RU")) {
          callback();
        } else {
          callback(new Error("Номер телефона некорректен"));
        }
      }, 1000);
    };

    const validatePass = (rule, value, callback) => {
      rule;
      if (value === "") {
        callback(new Error("Пожалуйста, введите пароль"));
      } else {
        if (this.registrationForm.c_password !== "") {
          this.$refs.registrationForm.validateField("c_password");
        }
        callback();
      }
    };

    const validatePass2 = (rule, value, callback) => {
      rule;
      if (value === "") {
        callback(new Error("Пожалуйста, подтвердите пароль"));
      } else if (value !== this.registrationForm.password) {
        callback(new Error("Пароли не совпадают"));
      } else {
        callback();
      }
    };

    const validateAgreeCheckbox = (rule, value, callback) => {
      rule;
      if (value !== true) {
        callback(new Error("Необходимо дать согласие"));
      } else {
        callback();
      }
    };

    return {
      registrationForm: {
        name: "",
        email: "",
        phone: "",
        password: "",
        c_password: "",
        agreeCheckbox: false,
      },
      rules: {
        name: [
          {
            required: true,
            message: "Пожалуйста, введите имя",
            trigger: "blur",
          },
        ],
        email: [{ validator: validateEmail, trigger: "change" }],
        phone: [{ validator: validatePhone, trigger: "change" }],
        password: [{ validator: validatePass, trigger: "change" }],
        c_password: [{ validator: validatePass2, trigger: "change" }],
        agreeCheckbox: [
          { validator: validateAgreeCheckbox, trigger: "change" },
        ],
      },
    };
  },
  methods: {
    registration: function () {
      this.$refs.registrationForm.validate((valid) => {
        if (valid) {
          register(
            {
              name: this.registrationForm.name,
              email: this.registrationForm.email,
              phone: this.registrationForm.phone.replace(/(\D)/g, ""),
              password: this.registrationForm.password,
              c_password: this.registrationForm.c_password,
              privacy: this.registrationForm.agreeCheckbox,
            },
            (response) => {
              if (response.data.success && response.data.data.access_token) {
                login({
                  access_token: response.data.data.access_token,
                  user: response.data.data,
                });
                this.$router.push("/admin");
              } else {
                this.$notification.open({
                  message: "Ошибка регистрации",
                  description: response.data.message,
                });
              }
            }
          );
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap");

.registration-header {
  font-weight: 900;
  font-size: 34px;
  line-height: 32px;
  color: black;
  width: 100%;
  text-align: left;
}

.registration-sub-header {
  padding: 15px 0px 27px 0px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: black;
  width: 100%;
  text-align: left;
}

.registration-form {
  padding: 0px 0px;
  width: 100%;
  text-align: left;
}

.registration-input-wrapper {
  padding: 0px;
}

.registration-form-last-line {
  display: flex;
  justify-content: space-between;
  align-items: top;
  padding: 0px;
}

.registration-agreeCheckbox-wrapper {
  display: flex;
  flex: 4;
}
.registration-agreeCheckbox {
  padding: 5px 10px 0px 0px;
}

.registration-agreeCheckbox-text {
  padding-top: 3px;
  line-height: 25px;
}

.ant-btn-link {
  padding: 0px;
}

.registration-submit-button-wrapper {
  padding-top: 10px;
}

.registration-submit-button {
  width: 100%;
  background-color: var(--use-color-primary-600);
  color: white;
  border: none;
  padding: 16px;
  font-weight: 500;
  font-size: 18px;
  border-radius: 2px;
  height: 55px;
  line-height: 24px;
  cursor: pointer;
}

.registration-politics {
  padding: 50px 0px 0px 0px;
  color: #676767;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  width: 100%;
}

.i-have-account-wrapper {
  font-size: 14px;
  line-height: 25px;
  flex: 2;
  text-align: right;
  padding-top: 4px;
}

@media (max-width: 507px) {
  .registration-header {
    font-size: 18px;
    padding-top: 28px;
  }
  .registration-sub-header {
    padding: 0px 0px 0px 0px;
    font-size: 10px;
    line-height: 14px;
  }
  .registration-social-button {
    width: 75px;
  }
}
</style>
