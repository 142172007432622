<template>
  <div class="checkSms-wrapper">
    <div class="checkSms-header">Восстановление пароля</div>
    <div class="checkSms-sub-header">
      На ваш номер телефона был выслан код для восстановления пароля
    </div>
    <form class="checkSms-form" @submit.prevent="checkSms">
      <div class="checkSms-input-wrapper">
        <div class="checkSms-input-label">
          <label for="smsCode">код из СМС</label>
        </div>
        <div class="checkSms-input-container">
          <input
            placeholder="введите"
            v-model="smsCode"
            size="large"
            class="checkSms-input"
            id="smsCode"
          />
        </div>
      </div>
      <div class="checkSms-submit-button-wrapper">
        <button class="checkSms-submit-button" type="submit">
          Восстановить пароль
        </button>
      </div>
    </form>
  </div>
</template>

<script>
// import axios from "axios";

// import { login } from "@/helpers/authHelper";

export default {
  name: "CheckSms",
  data() {
    return {
      smsCode: "",
      apiUrl: process.env.VUE_APP_SERVER_URL + process.env.VUE_APP_API_PATH,
    };
  },
  methods: {
    checkSms: function () {
      this.$emit("nextStep");
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap");

.checkSms-header {
  font-weight: 900;
  font-size: 34px;
  line-height: 32px;
  color: black;
  width: 100%;
  text-align: left;
}

.checkSms-sub-header {
  padding: 15px 0px 80px 0px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: black;
  width: 100%;
  text-align: left;
  content: "";
}

.checkSms-form {
  width: 100%;
  text-align: left;
}

.checkSms-input-wrapper {
  opacity: 0.5;
  background-color: #f7f7f7;
  border-radius: 15px;
  text-align: center;
}

.checkSms-input-label {
  padding-top: 42px;
}

.checkSms-input-container {
  padding: 12px;
}

.checkSms-input {
  padding: 12px 0px;
  width: 100%;
  background-color: #0000;
  padding: 25px;
  text-align: center;
  font-family: Roboto;
  font-size: 40px;
  font-weight: 400;
  line-height: 47px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-style: normal;
  outline: none;
  border: none;
}

.checkSms-submit-button-wrapper {
  padding-top: 30px;
}

.checkSms-submit-button-wrapper {
  padding-top: 16;
}

.checkSms-submit-button {
  width: 100%;
  background-color: var(--use-color-primary-600);
  color: white;
  border: none;
  padding: 16px;
  font-weight: 500;
  font-size: 18px;
  border-radius: 2px;
  height: 55px;
  line-height: 24px;
  cursor: pointer;
}

@media (max-width: 507px) {
  .checkSms-header {
    font-size: 18px;
    padding-top: 28px;
  }
}
</style>
