<template>
  <div v-if="isLogged()" class="exit">
    <a @click.prevent="onClickLogout">Выход</a>
  </div>
</template>

<script>
import { logout, isLogged } from "@/helpers/authHelper";

export default {
  name: "Logout",
  methods: {
    onClickLogout() {
      logout();
    },
    isLogged() {
      return isLogged();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
