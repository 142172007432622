<template>
  <a-spin :spinning="loading">
    <div class="white-popup popup_edit_profile" id="edit_profile">
      <div class="top">Профиль</div>
      <form class="popup_form">
        <div class="popup_title">Заведение</div>
        <div class="popup_rest">
          <div class="popup_top_left">
            <div class="popup_form_block">
              <label>Наименование заведения<span>*</span></label>
              <input class="ready" type="text" v-model="restaurant" />
            </div>
            <div class="popup_form_block">
              <label>Сайт</label>
              <input class="ready" type="url" v-model="url" />
            </div>
          </div>
          <div class="popup_top_right">
            <label>Логотип<span>*</span></label>
            <LogoDropper :logo="logo" @change="(item) => (logo = item)" />
          </div>
        </div>
        <div class="popup_title">Личные данные</div>
        <div class="popup_personal">
          <div class="first_column">
            <div class="popup_form_block">
              <label>Имя<span>*</span></label>
              <input class="ready" type="text" v-model="userName" />
            </div>
            <div class="popup_form_block">
              <label>Фамилия</label>
              <input class="ready" type="text" v-model="userSurname" />
            </div>
            <div class="popup_form_block">
              <label>Тел<span>*</span></label>
              <input class="ready" type="tel" v-model="userTel" />
            </div>
            <div class="popup_form_block">
              <label>Почта<span>*</span></label>
              <input class="ready" type="email" v-model="userEmail" />
            </div>
          </div>
          <div class="second_column">
            <div class="popup_form_block">
              <label>Наименование юрлица</label>
              <input class="ready" type="text" v-model="legalName" />
            </div>
            <div class="popup_form_block">
              <label>ИНН</label>
              <input class="ready" type="text" v-model="inn" />
            </div>
            <div class="popup_form_block">
              <label>Расчетный счет</label>
              <input class="ready" type="text" v-model="checkingAccount" />
            </div>
            <div class="popup_form_block">
              <label>Номер БИК</label>
              <input class="ready" type="text" v-model="bik" />
            </div>
          </div>
          <div class="third_column">
            <div class="popup_form_block">
              <label>Фактический адрес</label>
              <input class="ready" type="text" v-model="actualAddress" />
            </div>
            <div class="popup_form_block">
              <label>Юридический адрес</label>
              <input class="ready" type="text" v-model="legalAddress" />
            </div>
            <div class="popup_form_block">
              <label>ОГРН</label>
              <input class="ready" type="text" v-model="ogrn" />
            </div>
            <div class="popup_form_block">
              <label>Наименование банка</label>
              <input class="ready" type="text" v-model="bankName" />
            </div>
          </div>
        </div>
        <div class="req_info">
          <span>*</span> - Поля обязательны к заполнению
        </div>
        <input
          @click.prevent="saveCard"
          type="submit"
          value="Сохранить"
          :disabled="!isTouched()"
        />
      </form>
    </div>
  </a-spin>
</template>

<script>
import { getBrand, putBrand } from "@/helpers/dataHelpers";
import { bus } from "@/bus";

import LogoDropper from "@/components/common/LogoDropper";

const bankArray = ["ПАО “Сбербанк”", "АО “МММ”"];

export default {
  name: "EditProfile",
  data() {
    return {
      loading: true,
      id: "",
      logo: "",
      url: "",
      restaurant: "",
      userName: "",
      userSurname: "",
      userTel: "",
      userEmail: "",
      legalAddress: "",
      inn: "",
      checkingAccount: "",
      bik: "",
      actualAddress: "",
      ogrn: "",
      bankName: "",
      counterpartyAccount: "",
      bankArray,
      legalName: "",
    };
  },
  components: { LogoDropper },
  methods: {
    saveCard: function () {
      if (!this.restaurant) {
        this.$message.error("Введите название локации");
        return;
      }

      if (!this.userName) {
        this.$message.error("Введите ваше имя");
        return;
      }

      if (!this.userTel) {
        this.$message.error("Введите ваш номер телефона");
        return;
      }

      if (!this.userEmail) {
        this.$message.error("Введите ваш email");
        return;
      }

      const data = {
        site: this.url,
        company_name: this.restaurant,
        name: this.userName,
        surname: this.userSurname,
        phone: this.userTel,
        email: this.userEmail,
        legal_address: this.legalAddress,
        inn: this.inn,
        checking_account: this.checkingAccount,
        bik: this.bik,
        address: this.actualAddress,
        ogrn: this.ogrn,
        bank_name: this.bankName,
        counterparty_account: this.counterpartyAccount,
        legal_name: this.legalName,
        logo: this.logo,
      };
      putBrand(data, this.id, (response) => {
        bus.$emit("EditProfile update and close", response.data.data);
      });
    },
    isTouched() {
      return true;
    },
  },
  mounted: function () {
    getBrand((response) => {
      const data = response.data.data;
      this.loading = false;
      this.id = data.brandId;
      this.logo = data.logo;
      this.url = data.site;
      this.restaurant = data.company_name;
      this.userName = data.owner.name;
      this.userSurname = data.owner.surname;
      this.userTel = data.owner.phone;
      this.userEmail = data.owner.email;
      this.legalAddress = data.legal_address;
      this.inn = data.inn;
      this.checkingAccount = data.checking_account;
      this.bik = data.bik;
      this.actualAddress = data.address;
      this.ogrn = data.ogrn;
      this.bankName = data.bank_name;
      this.counterpartyAccount = data.counterparty_account;
      this.legalName = data.legal_name;
    });
  },
};
</script>

<style scoped></style>
