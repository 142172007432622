<template>
  <div class="footer">
    <div class="container">
      <div class="top">
        <div class="footer_menu">
          <ul>
            <li></li>
          </ul>
        </div>
      </div>
      <div class="bottom">
        <!-- <div class="info_links"> -->
        <span
          ><a class="footer_menu" href="mailto:info@usekit.ru" target="_blank"
            >Техпомощь - info@usekit.ru</a
          ></span
        ><span class="center-text"
          >Система развития опыта клиентских сервисов и лояльности</span
        >
        <!-- </div> -->
        <div class="copyright">© USEKIT 2021</div>
      </div>
    </div>
  </div>
</template>

<script>
import { isLogged } from "@/helpers/authHelper";

export default {
  name: "Footer",
  methods: {
    isLogged() {
      return isLogged();
    },
  },
};
</script>

<style scoped>
.footer_menu {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.center-text {
  position: relative;
  margin: 0 10px;
}

@media (max-width: 1000px) {
  .center-text {
    top: 0px;
  }
}
</style>
