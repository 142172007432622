import { getPets } from "@/helpers/4laps/dataHelpers";
import moment from "moment/moment";

function mappingB2F(ob) {
  return {
    phone: ob.phone,
    phone_2: ob.phone_2,
    sms_code: ob.sms_code,
    locations: ob.locations,
    key: ob.id,
    owner_name: ob.owner_name,
    pet_name: ob.pet_name,
    qr: ob.uuid,
    date: moment.utc(ob.created_at, "YYYY-MM-DD[T]HH:mm:ss.SSSSSS[Z]").local(),
  };
}

export const forLapsStore = {
  state: {
    pets: [],
    totalPets: 0,
    pagePets: 1,
    itemsPerPage: 10,
    loadPets: true,
  },
  getters: {
    getPets(state) {
      return state.pets;
    },
    getTotalPets(state) {
      return state.totalPets;
    },
    getPagePets(state) {
      return state.pagePets;
    },
    getLoadPets(state) {
      return state.loadPets;
    },
    getItemsPerPage(state) {
      return state.itemsPerPage;
    },
    getQuery(state) {
      return `?limit=${state.itemsPerPage}&page=${state.pagePets}`;
    },
  },
  mutations: {
    setPets(state, payload) {
      state.pets = payload.pets;
      state.totalPets = payload.totalPets;
      state.pagePets = payload.pagePets;
      state.itemsPerPage = payload.itemsPerPage;
      state.loadPets = false;
    },
    setPagePets(state, payload) {
      state.pagePets = payload;
    },

    setItemsPerPage(state, payload) {
      state.itemsPerPage = payload;
    },
  },
  actions: {
    setPagePets(state, query) {
      state.commit("setPagePets", { query });
    },
    setItemsPerPage(state, query) {
      state.commit("setItemsPerPage", { query });
    },
    getPetsActions(state, query) {
      getPets((response) => {
        state.commit("setPets", {
          pets: response.data.data.data.map(mappingB2F),
          totalPets: response.data.data.total,
          pagePets: response.data.data.current_page,
          itemsPerPage: response.data.data.per_page,
        });
      }, query);
    },
  },
};
