<template>
  <div class="myDropzone">
    <div
      class="remove"
      @click.stop="
        () => {
          loading = false;
          $emit('change', '');
        }
      "
    ></div>

    <div class="img-container">
      <div v-if="logo" class="img_logo">
        <img
          :src="logo[0] === 'd' ? logo : host + logo"
          alt="logo не загрузилось с сервера"
        />
      </div>
      <div v-else>
        <a-icon :type="loading ? 'loading' : 'plus'" />
        <div class="ant-upload-text">Перетащите или загрузите (jpg,png)</div>
      </div>
    </div>

    <a-upload
      class="antd-dropzone"
      name="avatar"
      list-type="picture-card"
      :show-upload-list="false"
      :customRequest="handleUpload"
      accept="image/png, image/jpeg"
      >1
    </a-upload>
  </div>
</template>

<script>
import { uploadImage } from "@/helpers/dataHelpers";

export default {
  name: "LogoDropper",
  props: ["logo"],
  data() {
    return {
      loading: false,
      host: process.env.VUE_APP_SERVER_URL,
      apiUrl:
        process.env.VUE_APP_SERVER_URL +
        process.env.VUE_APP_API_PATH +
        "/image",
    };
  },

  methods: {
    handleUpload: function (options) {
      //setFileUploading(true);
      const file = options.file;

      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("Формат файла неверен!");
        return;
      }

      const maxSizeMB = 0.5;
      const isLt2M = file.size / 1024 / 1024 < maxSizeMB;
      if (!isLt2M) {
        this.$message.error(`Размер изображения превывшает ${maxSizeMB}MB!`);
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const dataURI = e.target.result;

        if (dataURI) {
          uploadImage({ image: dataURI }, (response) => {
            this.$emit("change", response.data.data);
            this.loading = false;
          });
        }
      };
      reader.readAsDataURL(file);

      // setFileUploading(false);
    },
  },
};
</script>

<style lang="scss">
.ant-upload.ant-upload-select-picture-card,
.ant-upload-picture-card-wrapper {
  height: 100% !important;
  width: 100% !important;
  margin: 0px;
  padding: 0px;
  opacity: 0;
  position: absolute;
  top: 0;
}

.myDropzone {
  position: relative;
}

.img-container {
  width: 100%;
  .img_logo {
    img {
      max-height: 125px;
      max-width: 100%;
    }
  }
}
</style>
