import Vue from "vue";
import router from "./router";
import store from "./store";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import VueHtml2Canvas from "vue-html2canvas";
import Print from "vue-print-nb";
import VueMask from "v-mask";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebook,
  faVk,
  faGooglePlus,
  faApple,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import App from "./App.vue";

import YmapPlugin from 'vue-yandex-maps'

import Meta from "vue-meta";

const settingsMap = {
  apiKey: '',
  lang: 'ru_RU',
  coordorder: 'latlong',
  enterprise: false,
  version: '2.1'
}

Vue.use(YmapPlugin, settingsMap)

// add styles for project
process.env.VUE_APP_TYPE === "FOURLAPS"
    ? import(`./assets/styles/fourlaps/index.scss`)
    : "";

// add variable-styles for project
// common styles import in
process.env.VUE_APP_TYPE === "FOURLAPS"
    ? import(`./assets/styles/fourlaps/App.variables.4laps.css`)
    : import("./assets/styles/common/App.variables.default.css");

// add common styles for project
import "./assets/styles/common/App.css";

Vue.config.productionTip = false;
Vue.use(Antd);
Vue.use(VueHtml2Canvas);
Vue.use(Print);

library.add(faFacebook);
library.add(faVk);
library.add(faGooglePlus);
library.add(faApple);

Vue.use(VueMask);
Vue.use(Meta);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component(VueQrcode.name, VueQrcode);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
