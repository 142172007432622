import { notification } from "ant-design-vue";
import axios from "axios";
import { getToken, logout } from "@/helpers/authHelper";

const apiUrl = process.env.VUE_APP_SERVER_URL + process.env.VUE_APP_API_PATH;

export function getBearer() {
  return "Bearer " + getToken();
}

function addQuery(req, query) {
  if (!query) return;
  const keys = Object.keys(query);
  if (keys) {
    req.url = req.url + "?";
    keys.forEach((key) => (req.url = req.url + key + "=" + query[key] + "&"));
  }
}

export function axioWrapper(req, cb) {
  axios({
    ...req,
    url: apiUrl + req.url,
    headers: {
      Authorization: getBearer(),
    },
  })
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      let description = error.response?.status + " ";
      let message = "Сетевая ошибка";

      if (error.response?.status === 401) {
        logout();
        return;
      } else if (error.response?.status === 422) {
        if (
          error.response?.data?.data?.email[0] ===
          "The email has already been taken."
        ) {
          message = "Данный e-mail уже зарегистрирован";
          description = undefined;
        } else if (
          error.response?.data?.data?.url[0] ===
          "The url has already been taken."
        ) {
          description = "Данный url уже зарегистрирован";
        } else {
          description = "Ошибка " + error.response?.status;
        }
      } else {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          Object.keys(error.response.data.errors || {}).forEach((key) =>
            error.response.data.errors[key].forEach((text) => {
              description += text + ", ";
            })
          );
        }
      }

      notification.open({
        message,
        description,
      });
    });
}

export function register(data, cb) {
  const req = {
    method: "post",
    url: "/register",
    data,
  };
  axioWrapper(req, cb);
}

export function getPoints(cb) {
  const req = {
    method: "get",
    url: "/points",
  };
  axioWrapper(req, cb);
}

export function postPoints(data, cb) {
  const req = {
    method: "post",
    url: "/points",
    data,
  };
  axioWrapper(req, cb);
}

export function putPoints(id, data, cb) {
  const req = {
    method: "put",
    url: "/points/" + id,
    data,
  };
  axioWrapper(req, cb);
}

export function deletePoint(id, cb) {
  const req = {
    method: "delete",
    url: "/points/" + id,
  };
  axioWrapper(req, cb);
}

export function getUsers(cb) {
  const req = {
    method: "get",
    url: "/users",
  };
  axioWrapper(req, cb);
}

export function postUsers(cb) {
  const req = {
    method: "post",
    url: "/users",
  };
  axioWrapper(req, cb);
}

export function putUser(id, data, cb) {
  const req = {
    method: "put",
    url: "/users/" + id,
    data,
  };
  axioWrapper(req, cb);
}

export function postManager(data, cb) {
  const req = {
    method: "post",
    url: "/manager",
    data,
  };
  axioWrapper(req, cb);
}

export function getResponses(query, cb) {
  const req = {
    method: "get",
    url: "/responses",
  };
  addQuery(req, query);
  axioWrapper(req, cb);
}

export function getUserInfo(cb) {
  const req = {
    method: "get",
    url: "/userinfo",
  };
  axioWrapper(req, cb);
}

export function uploadImage(data, cb) {
  const req = {
    method: "post",
    url: "/image",
    data,
  };
  axioWrapper(req, cb);
}

export function getBrand(cb) {
  const req = {
    method: "get",
    url: "/brands",
  };
  axioWrapper(req, cb);
}

export function putBrand(data, id, cb) {
  const req = {
    method: "put",
    url: "/brands/" + id,
    data,
  };
  axioWrapper(req, cb);
}

export function putStatus(data, cb) {
  const req = {
    method: "put",
    url: "/status",
    data,
  };
  axioWrapper(req, cb);
}
