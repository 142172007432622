<template>
  <div>
    {{ version }}
  </div>
</template>

<script>
import { version } from "../../../package.json";
//packageJson.version

export default {
  name: "Version",
  data() {
    return {
      version,
    };
  },
};
</script>

<style scoped></style>
