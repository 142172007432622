<template>
  <div>
    <div class="login-header">Личный кабинет</div>
    <div class="login-sub-header"></div>
    <form class="login-form" @submit.prevent="login">
      <a-input
        placeholder="E-mail"
        v-model="email"
        size="large"
        class="login-input-wrapper"
      >
        <a-icon
          slot="prefix"
          type="user"
          :style="{ fontSize: '16px', color: '#08c' }"
        />
      </a-input>

      <a-input-password
        placeholder="Пароль"
        v-model="password"
        size="large"
        class="login-input-wrapper"
      >
        <a-icon
          slot="prefix"
          type="lock"
          :style="{ fontSize: '16px', color: '#08c' }"
        />
      </a-input-password>

      <div class="login-form-last-line">
        <div>
          <a-checkbox v-model="rememberCheckbox" class="login-rememberCheckbox"
            >Запомнить</a-checkbox
          >
        </div>
        <div class="login-recovery_password-link">
          <router-link to="/password_recovery">Забыли пароль?</router-link>
        </div>
      </div>

      <div class="login-submit-button-wrapper">
        <button class="login-submit-button" type="submit">Войти</button>
      </div>
    </form>

    <div class="login-politics"><Disclaimer text="Войти" /></div>
  </div>
</template>

<script>
import axios from "axios";

import Disclaimer from "@/components/common/Disclaimer";
import { login } from "@/helpers/authHelper";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      rememberCheckbox: false,
      apiUrl: process.env.VUE_APP_SERVER_URL + process.env.VUE_APP_API_PATH,
    };
  },
  components: { Disclaimer },
  methods: {
    login: function () {
      axios
        .post(
          this.apiUrl + "/login",
          {
            email: this.email,
            password: this.password,
          },
          // {
          //   headers: {
          //     "Access-Control-Allow-Origin": "*",
          //   },
          // }
        )
        .then((response) => {
          if (response.data.access_token) {
            login(response.data, this.rememberCheckbox);
            this.$router.push("/admin");
          } else {
            this.$notification.open({
              message: "Ошибка входа в систему",
              description: response.data.message,
            });
          }
        })
        .catch((error) => {
          if (error.response && error.response.status) {
            let description = error.response.status + " ";
            Object.keys(error.response.data.errors || {}).forEach((key) =>
              error.response.data.errors[key].forEach((text) => {
                description += text + ", ";
              })
            );
            this.$notification.open({
              message: "Сетевая ошибка входа в систему",
              description,
            });
          }
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap");

.login-header {
  font-weight: 900;
  font-size: 34px;
  line-height: 32px;
  color: black;
  width: 100%;
  text-align: left;
}

.login-sub-header {
  padding: 15px 0px 27px 0px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: black;
  width: 100%;
  text-align: left;
}

.login-form {
  padding: 0px 0px;
  width: 100%;
  text-align: left;
}

.login-input-wrapper {
  padding: 12px 0px;
}

.login-form-last-line {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 10px 0px 0px 0px;
}

.login-rememberCheckbox {
  padding: 5px 0px;
}

.login-recovery_password-link {
  padding-top: 3px;
  color: var(--use-color-primary-600);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.login-submit-button-wrapper {
  padding-top: 20px;
}

.login-submit-button {
  width: 100%;
  background: var(--use-color-primary-600);
  border-radius: 2px;
  color: white;
  border: none;
  padding: 16px;
  font-weight: 500;
  font-size: 18px;
  height: 55px;
  line-height: 24px;
  cursor: pointer;
}

.login-politics {
  padding: 30px 0px 0px 0px;
  color: #676767;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  width: 100%;
}

@media (max-width: 507px) {
  .login-header {
    font-size: 18px;
    padding-top: 28px;
  }
  .login-sub-header {
    padding: 0px 0px 0px 0px;
    font-size: 10px;
    line-height: 14px;
  }
}
</style>
