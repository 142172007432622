<template>
  <div class="password_recovery-wrapper">
    <PasswordRecoveryStep1 v-if="step == 1" @nextStep="step = 2" />
    <PasswordRecoveryStep2 v-if="step == 2" @nextStep="step = 3" />
    <PasswordRecoveryStep3 v-if="step == 3" @nextStep="step = 4" />
    <PasswordRecoveryStep4 v-if="step == 4" />
    <div class="password_recovery-form-last-line">
      <a-button type="link" @click="goBack">Назад</a-button>
    </div>
    <div class="password_recovery-politics">
      <Disclaimer text="Отправить" />
    </div>
  </div>
</template>

<script>
import PasswordRecoveryStep1 from "@/components/common/PasswordRecovery.Step1";
import PasswordRecoveryStep2 from "@/components/common/PasswordRecovery.Step2";
import PasswordRecoveryStep3 from "@/components/common/PasswordRecovery.Step3";
import PasswordRecoveryStep4 from "@/components/common/PasswordRecovery.Step4";
import Disclaimer from "@/components/common/Disclaimer";

export default {
  name: "PasswordRecovery",
  data() {
    return {
      step: 1,
    };
  },
  methods: {
    goBack: function () {
      this.$router.back();
    },
  },
  components: {
    PasswordRecoveryStep1,
    PasswordRecoveryStep2,
    PasswordRecoveryStep3,
    PasswordRecoveryStep4,
    Disclaimer,
  },
};
</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap");

.password_recovery-form-last-line {
  text-align: center;
  padding: 32px 0px 0px 0px;
}

.password_recovery-politics {
  padding: 30px 0px 0px 0px;
  color: #676767;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  width: 100%;
}
</style>
