<template>
  <div class="responsive-wrapper">
    <div class="responsive-center">
      <div class="responsive-img-top-wrapper">
        <img v-if="el.is.showTop" :src="pngTop" class="responsive-img-top" />
      </div>
      <div
        class="responsive-login-wrapper"
        :class="{
          'responsive-login-wrapper-small': el.is.showTop,
        }"
      >
        <div class="login-wrapper">
          <slot></slot>
        </div>

        <div class="responsive-img-wrapper">
          <img v-if="el.is.showRight" :src="pngRight" class="responsive-img" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ResponsiveMixin } from "vue-responsive-components";

import pngRight from "@/assets/Group2547.png";
import pngTop from "@/assets/Group2925.png";

export default {
  name: "Home",
  data() {
    return {
      pngRight,
      pngTop,
    };
  },
  mixins: [ResponsiveMixin],
  breakpoints: {
    showTop: (el) => el.width >= 507 && el.width <= 992,
    showRight: (el) => el.width > 992,
  },
};
</script>

<style scoped lang="scss">
.responsive-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .responsive-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .responsive-img-top {
      position: relative;
      left: 0px;
      top: -10vh;
      width: 80vw;
      z-index: 100;
    }

    .responsive-login-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      &.responsive-login-wrapper-small {
        position: relative;
        top: -25vh;
      }

      .login-wrapper {
        padding: 80px 70px;
        font-family: "Roboto", sans-serif;
        background-color: white;
        min-width: 580px;
        width: 580px;
        border-radius: 20px;

        &::after {
          content: "";
          min-width: 580px;
          width: 580px;
          transform: translateX(-50%);
          position: fixed;
          height: 1000px;
          background-color: white;
        }
      }

      .responsive-img-wrapper {
        max-width: 40vw;

        .responsive-img {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .login-wrapper {
    padding: 35px;
    min-width: 514px;
    width: 514px;
    border-radius: 20px;
  }
}

@media (max-width: 575px) {
  .login-wrapper {
    padding: 46px 27px 35px 23px;
    min-width: 507px;
    width: 507px;
    border-radius: 20px;
  }
}

@media (max-width: 507px) {
  .login-wrapper {
    padding: 0px 20px 20px 20px;
    margin: -40px 15px 0px 15px;
    width: 100%;
    border-radius: 20px;
    min-width: 0px;
  }
}
</style>
